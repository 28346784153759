/**
 * Created by jimmy on 2019/11/8.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
const apiPrefix = 'https://api.shareyourhealth.cn';
// const apiPrefix = 'http://192.168.10.9:8080';

export const getHeaders = (conf = {}) => {
	let userInfo = sessionStorage.getItem('userinfo');
	userInfo = userInfo ? JSON.parse(userInfo) : {};
	const _header = {
		"Content-Type":"application/json; charset=utf-8",
		"Auth-Token": userInfo && userInfo.yid ? userInfo.yid : '',
		...conf
	}
	return _header;
};

export const post = async (settings) => {
	let url = apiPrefix + settings.url;
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(settings.headers),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();
	
	response = await response.json();
	if(!settings.hideError && response.errCode !== 0) {
		Utils._alert(response.errMsg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	let url = apiPrefix + settings.url;
	
	if(settings.data) {
		let queryArr = [];
		for(let i in settings.data) {
			if(i && settings.data[i]){
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}
	
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders(settings.headers)
	});
	Utils.loading.hide();
	
	response = await response.json();
	if(!settings.hideError && response.errCode !== 0) {
		Utils._alert(response.errMsg);
		return false;
	}
	return response;
};