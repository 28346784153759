import React, { useState, useEffect } from 'react';
import {WhiteSpace, Toast, Result, InputItem} from 'antd-mobile'

import * as assessApi from '../../apis/assessApi';
import dsBridge from 'dsbridge';

import './index.less';
import IMG_AVATAR from './images/ic_assess_sug_avatar.png';
import IMG_WARNING from '../footAssess/images/ic_assess_warning.svg';
import IMG_CLOSE from '../register/images/ic_close.png';

const FootResultPage = props => {
  const [assess, setAssess] = useState({});
  const [loadStatus, setLoadStatus] = useState("NONE");
  const [showModal, setShowModal] = useState({
    show: false,
    index: 0,
    imgList: []
  });

  const fetchData = async (data) => {
    const assessId = props.match.params.assessId;

    setLoadStatus("LOADING");
    var isSuccess = true;

    var res = await assessApi.getAssess(assessId, data)
    if (res.errCode !== 0) {
      Toast.fail(res.errMsg);
      isSuccess = false;
    } else {
      setAssess(res);
    }

    setLoadStatus(isSuccess ? "LOADED" : "FAILED");
  };

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    if (query.get("vc") !== null) {
      const headers = {
        "X-Mp-LoginToken": query.get("vc"),
        "X-DeviceToken": query.get("vd"),
        "X-Mp-AppId": "wxd3025ff909e390bb",
        "X-Platform": "iOS",
        "X-App-Id": "HEALTH",
        "X-Version": "4.6.0",
        "X-Build": "20230401",
        "X-Channel": "AppStore"
      };
      fetchData(headers);
      return;
    }

    const params = {
      action: "getToken"
    };

    dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (data) => {
      if (data) {
        fetchData(JSON.parse(data) || {});
      } else {
        setLoadStatus("FAILED");
      }
    });
  }, [props.match.params.assessId])

  const onRetry = () => {
    const query = new URLSearchParams(props.location.search);
    if (query.get("vc") !== null) {
      const headers = {
        "X-Mp-LoginToken": query.get("vc"),
        "X-DeviceToken": query.get("vd"),
        "X-Mp-AppId": "wxd3025ff909e390bb",
        "X-Platform": "iOS",
        "X-App-Id": "HEALTH",
        "X-Version": "4.6.0",
        "X-Build": "20230401",
        "X-Channel": "AppStore"
      };
      fetchData(headers);
      return;
    }

    const params = {
      action: "getToken"
    };
    dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (data) => {
      if (data) {
        fetchData(JSON.parse(data) || {});
      } else {
        setLoadStatus("FAILED");
      }
    });
  }

  const onCourseTap = (prdId) => {
    const params = {
      action: "openProduct",
      productId: prdId
    };

    dsBridge.call("egghealth.syncHandler", JSON.stringify(params));
  }

  const onShowImage = (imgList, index) => {
    setShowModal({
      show: true,
      index: index,
      imgList: imgList
    });
  }

  const onHideImage = () => {
    setShowModal({
      show: false,
      index: 0,
      imgList: []
    });
  }

  const userData = JSON.parse(assess.submitData || "{}");
  const assessData = JSON.parse(assess.assessData || "{}");

  var userDesc = "问题描述：" + (userData.userComplaint || "无") + "\n";
  userDesc = userDesc + "补充描述：" + (userData.userRemark || "无");
  userDesc = userDesc.replace(/\n/g, "<br />");

  const userImageList = assessData.assessFrontImage ? [
    assessData.assessFrontImage,
    assessData.assessBackImage,
    assessData.assessSideImage,
  ] : [];

  const userMoveTitle = {
    "jiZhuWanQu": "脊柱弯曲测试",
    "jiZhuCeXiangWanQu": "脊柱侧向弯曲测试",
    "zouLouZiShiCeMian": "走路姿势",
    "gongJianBuZhengMianCeMian": "弓箭步",
    "shenDunZhengMianCeMian": "深蹲",
    "fuWoCheng": "俯卧撑",
    "jianWaiZhang": "肩外展测试",
    "jingCeQu": "颈侧屈+旋转测试",
    "fuWoHouShenCeShi": "俯卧后伸腿测试",
    "ceWoGuSiTouJiLaShen": "俯卧股四头肌拉伸测试",
    "zhiTuiTaiGao": "直腿抬高测试",
    "zuoZiXiongZhuiXuanZhuan": "坐姿胸椎旋转测试",
    "danTuiYinLa": "单腿硬拉测试",
    "tuoMaSi": "托马斯测试",
  };
  var moveKeyList = [];
  if (assess.pageTemplate === 'YAOBU_V1') {
    moveKeyList = ["jiZhuWanQu", "jiZhuCeXiangWanQu", "fuWoHouShenCeShi",
      "zhiTuiTaiGao", "zuoZiXiongZhuiXuanZhuan", "danTuiYinLa"];
  } else if (assess.pageTemplate === "ZUBU_V1") {
    moveKeyList = ["zouLouZiShiCeMian", "gongJianBuZhengMianCeMian",
      "shenDunZhengMianCeMian", "ceWoGuSiTouJiLaShen"];
  } else if (assess.pageTemplate === "JIANJING_V1") {
    moveKeyList = ["fuWoCheng", "jianWaiZhang", "jingCeQu"];
  } else if (assess.pageTemplate === "QUANSHEN_V1") {
    moveKeyList = Object.keys(userMoveTitle);
  }

  const userMoveList = [];
  for (let moveKey of moveKeyList) {
    if (assessData[moveKey] && assessData[moveKey].qiniuKey) {
      userMoveList.push({
        ...assessData[moveKey],
        title: userMoveTitle[moveKey] || "",
      });
    }
  }

  const testRiskLevelMap = {
    "0": {
      color: "#24C788",
      text: "无",
    },
    "1": {
      color: "#24C788",
      text: "轻微",
    },
    "2": {
      color: "#F5A623",
      text: "中度",
    },
    "3": {
      color: "#DC1B33",
      text: "严重",
    }
  }

  const assessSug = (assess.suggestion || "").replace(/\n/g, "<br />");
  const showWarning = (loadStatus === "FAILED");

  return (
      <div>
        <div style={{ display: showWarning ? "display" : "none" }}>
          <Result
              img={<img src={IMG_WARNING} className="assess-result-img" alt="" />}
              title="数据加载失败"
              message={<div className="assess-result-message">请刷新页面重试</div>}
          />
          <div className="assess-result-button" onClick={onRetry}>刷新重试</div>
        </div>
        <div className="assess-result-header">
          <div className="assess-result-header-title">{assess.title}</div>
          <div className="assess-result-header-id">{`NO.${assess.id}`}</div>
          <div className="assess-result-header-info">{`${assess.name} ｜ ${userData.age}岁 | 
          ${userData.height}cm | ${userData.weight}kg`}
          </div>
        </div>
        <div className="assess-result-section-title">症状自述</div>
        <div
            className="assess-result-section-desc"
            dangerouslySetInnerHTML={{ __html: userDesc }}>
        </div>

        {(assessData.hasWeiDuInfo || userData.hasOwnProperty("tuiWeiDuTestLeft5")) ? <table className="assess-test-table">
          <thead>
          <tr>
            <th style={{width: '20%'}}>位置</th>
            <th>左腿(cm)</th>
            <th>右腿(cm)</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>5cm</td>
            <td>{userData.tuiWeiDuTestLeft5}</td>
            <td>{userData.tuiWeiDuTestRight5}</td>
          </tr>
          <tr>
            <td>10cm</td>
            <td>{userData.tuiWeiDuTestLeft10}</td>
            <td>{userData.tuiWeiDuTestRight10}</td>
          </tr>
          <tr>
            <td>-10cm</td>
            <td>{userData.tuiWeiDuTestLeftMinus10}</td>
            <td>{userData.tuiWeiDuTestRightMinus10}</td>
          </tr>
          </tbody>
        </table> : null}

        <div className="assess-result-section-title">体态观察</div>
        <div className="assess-result-image-list">
          <ul>
            {userImageList.map((imgItem, index) => (
                <li key={imgItem.qiniuKey}>
                  <div className="assess-result-image-item">
                    <img
                        onClick={() => onShowImage(userImageList, index)}
                        src={imgItem.urlPrefix + "/" + imgItem.qiniuKey + "/w1024.75.1.jpg"}
                        alt="">
                    </img>
                    <div className="assess-result-image-title">
                      {index === 0 ? "正面" : (index === 1 ? "背面" : "侧面")}
                    </div>
                  </div>
                </li>
            ))}
          </ul>
        </div>

        <WhiteSpace size="lg"></WhiteSpace>
        <div className="assess-result-section-title">动作观察</div>
        <div className="assess-result-image-list">
          <ul>
            {userMoveList.map((imgItem, index) => (
                <li key={`moveKey_${imgItem.qiniuKey}`}>
                  <div className="assess-result-image-item">
                    <img
                        onClick={() => onShowImage(userMoveList, index)}
                        src={imgItem.urlPrefix + "/" + imgItem.qiniuKey + "/w1024.75.1.jpg"}
                        alt="">
                    </img>
                    <div className="assess-result-image-title">
                      {imgItem.title}
                    </div>
                  </div>
                </li>
            ))}
          </ul>
        </div>

        <WhiteSpace size="lg"></WhiteSpace>
        <div className="assess-result-section-title">动作测试</div>
        {(assessData.testList || []).map((test) => (
            <div className="assess-result-test-wrapper" key={test.key}>
              <div className="assess-result-test-title">{test.testName}</div>
                <div className="assess-result-test-info">
                <div className="assess-result-test-info-item">
                  <div className="assess-result-test-info-item-title">左侧</div>
                  <div className="assess-result-test-info-item-value">{test.leftValue === "0" ? "阴性" : "阳性"}</div>
                </div>
                <div className="assess-result-test-info-item">
                  <div className="assess-result-test-info-item-title">右侧</div>
                  <div className="assess-result-test-info-item-value">{test.rightValue === "0" ? "阴性" : "阳性"}</div>
                </div>
                <div className="assess-result-test-info-item">
                  <div className="assess-result-test-info-item-title">风险程度</div>
                  <div
                      className="assess-result-test-info-item-value"
                      style={{ color: testRiskLevelMap[test.riskLevel] ? testRiskLevelMap[test.riskLevel].color : "#24C788" }}
                  >
                    {testRiskLevelMap[test.riskLevel] ? testRiskLevelMap[test.riskLevel].text : test.riskLevel}
                  </div>
                </div>
              </div>
              <div className="assess-result-test-desc">{test.resultDesc}</div>
            </div>
        ))}

        <WhiteSpace size="lg"></WhiteSpace>
        <div className="assess-result-sug-wrapper">
          <div className="assess-result-sug-header">
            康复师建议
          </div>
          <div className="assess-result-sug-text" dangerouslySetInnerHTML={{ __html: assessSug }}></div>
        </div>

        <div className="assess-result-section-title">推荐训练</div>
        {(assess.trainingList || []).map((training) => (
            <div
                key={`training_${training.id}`}
                className="assess-result-training-wrapper"
                onClick={() => onCourseTap(training.productId)}
                style={{ backgroundImage: training.productInfo ? `url(${training.productInfo.coverUrl}/w1024.75.1.jpg)` : "" }}>
              <div className="assess-result-training-title">{training.productName}</div>
              <div className="assess-result-training-subtitle">{training.productInfo ? (training.productInfo.subtitle || "") : ""}</div>
            </div>
        ))}

        <div className="assess-result-placehlder"></div>
        {(assess.introList || []).map((intro, iIndex) => (
            <div key={`intro_${iIndex}`} className="assess-result-article-wrapper">
              <div className="assess-result-article-title">{intro.title}</div>
              {(intro.itemList || []).map((introItem, itemIndex) => (
                  <div key={`introItem_${iIndex}_${itemIndex}`} className="assess-result-article-item">
                    <div
                        className="assess-result-article-text"
                        dangerouslySetInnerHTML={{ __html: (introItem.text || "").replace(/\n/g, "<br />") }}
                    >
                    </div>
                    {(introItem.imageList || []).map((mediaItem, mIndex) => (
                        <div key={`media_${iIndex}_${itemIndex}_${mIndex}`} className="assess-result-article-media">
                          {mediaItem.videoKey ?
                              <video
                                  src={mediaItem.videoUrl}
                                  controls
                                  controlsList={"nodownload nofullscreen"}
                                  disablePictureInPicture
                                  playsInline
                                  x5-playsinline
                                  webkit-playsinline="true"
                                  poster={mediaItem.imageUrl + "/w1024.75.1.jpg"}
                              ></video> :
                              <img src={mediaItem.imageUrl + "/w1024.75.1.jpg"} alt=""></img>
                          }
                        </div>
                    ))}
                  </div>
              ))}
            </div>
        ))}
        <div className="assess-img-modal" style={{ display: showModal.show ? "block" : "none" }}>
          <div className="assess-modal-content">
            <div className="assess-modal-banner">
              <img
                  className="assess-modal-image"
                  src={showModal.show ? (showModal.imgList[showModal.index].urlPrefix + "/" + showModal.imgList[showModal.index].qiniuKey) : ''}
                  alt="">
              </img>
            </div>
            <div className="assess-modal-close" onClick={onHideImage}>
              <img src={IMG_CLOSE} alt=""></img>
            </div>
          </div>
        </div>
      </div>
  );
};

export default FootResultPage;