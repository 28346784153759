import React, { useState, forwardRef } from 'react';
import { Icon, Modal, Toast } from 'antd-mobile'
import { upload } from 'qiniu-js';

import './index.less';
import IMG_UPLOAD from './images/ic_upload_image.png';

const alert = Modal.alert;
const uniqueString = require('unique-string');
const UploadComp = forwardRef((props, _ref) => {
  const [fileUrl, setFileUrl] = useState();
  const [progress, setProgress] = useState();
  const [uploadStatus, setUploadStatus] = useState("NONE");
  const [uploadSub, setUploadSub] = useState();
  const [inputId, ] = useState(uniqueString());
  const [errMsg, setErrMsg] = useState("上传失败");

  const onFileChange = (e) => {
    var file = e.target.files[0];
    if (file === undefined || !props.beforeUpload(file)) {
      return;
    }

    setFileUrl(window.URL.createObjectURL(file));
    setUploadStatus("UPLOADING");

    if (props.getUploadToken) {
      props.getUploadToken((token) => {
        var observer = {
          next(res) {
            setProgress(res.total.percent.toFixed(0))
          },
          error(err) {
            setErrMsg("上传失败: " + JSON.stringify(err));
            setUploadStatus("FAILED");
            Toast.fail(err);
          },
          complete(res) {
            let mediaInfo = {
              ...res,
              qiniuKey: res.assetKey,
              bucket: token.bucket,
              urlPrefix: token.urlPrefix,
            };
            setUploadStatus("UPLOADED");
            props.onChange(mediaInfo);
          },
        };

        var observable = upload(file, null, token.token, null, {
          useCdnDomain: true,
          region: null,
        });
        var subscription = observable.subscribe(observer);
        setUploadSub(subscription);
      });
    } else {
      var observer = {
        next(res) {
          setProgress(res.total.percent.toFixed(0))
        },
        error(err) {
          setErrMsg("上传失败: " + JSON.stringify(err));
          setUploadStatus("FAILED");
          Toast.fail(err);
        },
        complete(res) {
          let mediaInfo = {
            ...res,
            qiniuKey: res.assetKey,
            bucket: props.uploadToken.bucket,
            urlPrefix: props.uploadToken.urlPrefix,
          };
          setUploadStatus("UPLOADED");
          props.onChange(mediaInfo);
        },
      };

      var observable = upload(file, null, props.uploadToken.token, null, {
        useCdnDomain: true,
        region: null,
      });
      var subscription = observable.subscribe(observer);
      setUploadSub(subscription);
    }
  }

  const handleRemoveFile = () => {
    alert('删除图片', '确认删除图片吗？', [
      { text: '取消', onPress: () => console.log('cancel') },
      { text: '确定', onPress: () => {
        if (uploadSub && uploadStatus === 'UPLOADING') {
          uploadSub.unsubscribe();
        }

        setUploadStatus('NONE');
        setUploadSub(null);
        props.onChange({});
      } },
    ]);
  }

  const mediaPreview = (uploadInfo) => (
    <div className="upload-media-preview" style={props.title === "上传视频" ? {height: "200px"} : {}}>
      <div className="upload-preview-image">
        {props.accept.startsWith("image") ?
          <img src={uploadInfo.url} alt=""></img> :
          <video
            controls
            controlsList={"nodownload nofullscreen"}
            disablePictureInPicture
            playsInline
            x5-playsinline
            webkit-playsinline="true"
            src={uploadInfo.url}
            poster={uploadInfo.posterUrl}
          >
          </video>
        }
      </div>
      <div className="upload-preview-image-btn">
            <Icon
              type="cross-circle-o"
              size="md"
              color={"rgba(0, 0, 0, 0.5)"}
              onClick={handleRemoveFile}
            />
      </div>
      <div className="upload-preview-status" style={{
            color: uploadInfo.status === "FAILED" ? "red" : "#fff",
            padding: "5px",
          }}
      >
          {uploadInfo.status === "UPLOADING" ? `上传中 ${(progress || 0.00)}%` : (uploadInfo.status === "FAILED" ? uploadInfo.errMsg : "上传完成")}
      </div>
    </div>
  );

  const uploadButton = (uploadInfo) => (
    <div className="upload-button" style={props.title === "上传视频" ? {height: "120px"} : {}} needsclick="true">
      <img src={IMG_UPLOAD} alt=""></img>
      <div style={{fontWeight: "bold"}}>{props.title}</div>
    </div>
  );

  let mediaInfo = props.value || {};
  let status = uploadStatus;
  let msg= errMsg || "上传失败";
  let url = fileUrl;
  let posterUrl;
  if (mediaInfo.qiniuKey) {
    status = 'UPLOADED';
    url = mediaInfo.urlPrefix + "/" + mediaInfo.qiniuKey +
      (mediaInfo.bucket === "syh-image" ? "/w500.jpg" : "");
    posterUrl = "https://syh-img.shareyourhealth.cn/" + mediaInfo.qiniuKey + ".jpg";
  }
  const uploadInfo = {
    errMsg: msg,
    status,
    url,
    posterUrl,
  };

  return (
    <div className="upload-wrapper">
      <input
        type="file"
        id={uploadInfo.status === 'NONE' ? inputId : ""}
        accept={props.accept}
        className="upload-input"
        onChange={onFileChange}
      />
      <label htmlFor={inputId} className="upload-label">
        {uploadInfo.status === 'NONE' ? uploadButton(uploadInfo) : mediaPreview(uploadInfo)}
      </label>
    </div>
  );
});

export default UploadComp;
