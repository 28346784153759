import React, { useState, useEffect } from 'react';
import { WhiteSpace, Toast, Result } from 'antd-mobile'

import * as assessApi from '../../apis/assessApi';
import dsBridge from 'dsbridge';

import './index.less';
import IMG_WARNING from '../footAssess/images/ic_assess_warning.svg';

const OfflineResultPage = props => {
  const [assess, setAssess] = useState({});
  const [loadStatus, setLoadStatus] = useState("NONE");

  const fetchData = async (data) => {
    const assessId = props.match.params.assessId;

    setLoadStatus("LOADING");
    var isSuccess = true;

    var res = await assessApi.getAssess(assessId, data)
    if (res.errCode !== 0) {
      Toast.fail(res.errMsg);
      isSuccess = false;
    } else {
      setAssess(res);
    }

    setLoadStatus(isSuccess ? "LOADED" : "FAILED");
  };

  useEffect(() => {
    const params = {
      action: "getToken"
    };

    dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (data) => {
      if (data) {
        fetchData(JSON.parse(data) || {});
      } else {
        setLoadStatus("FAILED");
      }
    });
  }, [props.match.params.assessId])

  const onRetry = () => {
    const params = {
      action: "getToken"
    };

    dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (data) => {
      if (data) {
        fetchData(JSON.parse(data) || {});
      } else {
        setLoadStatus("FAILED");
      }
    });
  }

  const userData = JSON.parse(assess.submitData || "{}");
  const showWarning = (loadStatus === "FAILED");

  return (
    <div>
      <div style={{ display: showWarning ? "display" : "none" }}>
        <Result
          img={<img src={IMG_WARNING} className="assess-result-img" alt="" />}
          title="数据加载失败"
          message={<div className="assess-result-message">请刷新页面重试</div>}
        />
        <div className="assess-result-button" onClick={onRetry}>刷新重试</div>
      </div>
      <div className="assess-result-header">
        <div className="assess-result-header-title">{assess.title}</div>
        <div className="assess-result-header-id">{`NO.${assess.id}`}</div>
        <div className="assess-result-header-info">{`${assess.name} ｜ ${userData.career}`}
        </div>
      </div>
      <WhiteSpace></WhiteSpace>
      <div className="assess-result-section-desc">
        已收到您的资料～
      </div>
      <WhiteSpace></WhiteSpace>
      <div className="assess-result-section-desc">
        评估报告和训练动作会在评估完成后3个工作日完成，并在App推送给您～
      </div>
      <WhiteSpace></WhiteSpace>
      <WhiteSpace></WhiteSpace>
    </div>
  );
};

export default OfflineResultPage;