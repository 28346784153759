import React, { forwardRef }from 'react';

import './index.less';

const SelectComp = forwardRef((props, _ref) => {
  const handleItemClicked = (e) => {
    const curValue = [].concat(props.value || []);
    const index = curValue.indexOf(e);
    if (index >= 0) {
      curValue.splice(index, 1);
    } else {
      curValue.push(e);
    }

    props.onChange(curValue);
  }

  return (
    <div className="select-wrapper">
      {props.options.map((oItem) => (
        <div
          className={(props.value || []).indexOf(oItem.value) >= 0 ?
            "select-item-selected" : "select-item-unselected"}
          onClick={() => handleItemClicked(oItem.value)}
        >
          {oItem.label}
        </div>
      ))}
    </div>
  );
});

export default SelectComp;