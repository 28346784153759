import React, { forwardRef }from 'react';

import './index.less';

const TextInputComp = forwardRef((props, _ref) => {
  const handleTextChange = (e) => {
    props.onChange(e.target.value);
  }

  return (
    <div className="text-input-wrapper">
      <div className="text-info">
        <div className="text-title">{props.title}</div>
        <input 
          type={props.type || "text"} 
          value={props.value}
          className="text-input"
          pattern={props.type === "number" ? "[0-9]*" : ""}
          placeholder={props.placeholder}
          onChange={handleTextChange}
        ></input>
      </div>
    </div>
  );
});

export default TextInputComp;