import React, { useEffect, useState } from 'react';
import createDOMForm from 'rc-form/lib/createDOMForm';
import { TextareaItem, WhiteSpace, Toast, Result } from 'antd-mobile'

import * as assessApi from '../../apis/assessApi';
import dsBridge from 'dsbridge';

import './index.less';
import IMG_INFO from './images/ic_assess_info.png';
import IMG_WAITING from './images/ic_assess_waiting.svg';
import IMG_WARNING from './images/ic_assess_warning.svg';

import TextInputComp from '../../components/textInput';
import DatePickerComp from '../../components/picker/datePicker';
import PickerComp from "../../components/picker";
import SelectComp from "../../components/select";

const OfflineAssessPage = props => {
  const [headers, setHeaders] = useState({});
  const [assess, setAssess] = useState({});
  const [assessData, setAssessData] = useState({});
  const [loadStatus, setLoadStatus] = useState("NONE");

  const getTokenAndFetchData = async () => {
    if (headers && headers["X-Mp-LoginToken"]) {
      fetchData();
    } else {
      const params = {
        action: "getToken"
      };

      dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (data) => {
        if (data) {
          var tokenInfo = JSON.parse(data) || {};
          setHeaders(tokenInfo);
          fetchData(tokenInfo);
        } else {
          setLoadStatus("FAILED");
        }
      });
    }
  }

  const fetchData = async (tokenInfo) => {
    const assessId = props.match.params.assessId;
    setLoadStatus("LOADING");
    var isSuccess = true;

    var res = await assessApi.getAssess(assessId, tokenInfo)
    if (res.errCode !== 0) {
      Toast.fail(res.errMsg);
      isSuccess = false;
    } else {
      let tmpValues = res.submitData ? JSON.parse(res.submitData) : {};
      setAssessData(tmpValues);
      setAssess(res);
    }

    setLoadStatus(isSuccess ? "LOADED" : "FAILED");
  };

  useEffect(() => {
    // const assessId = props.match.params.assessId;

    getTokenAndFetchData();
    // return () => {
    //   if (assess.status !== "SUBMITTED") {
    //     let values = props.form.getFieldsValue([]);
    //     localStorage.setItem(`ASSESS_DATA_${assessId}`, JSON.stringify(values));
    //   }
    // }
  }, [props.match.params.assessId])

  const onSubmit = (e) => {
    const { assessId } = props.match.params;

    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        getTokenAndSubmitAssess({
          id: assessId,
          name: values.name,
          coverKey: (values.userTunQiaoLeftImage || {}).qiniuKey,
          submitData: JSON.stringify(values),
        });
      } else {
        Toast.fail("资料不完整，请填写完整后提交～");
      }
    });
  }

  const onSaveDraft = (e) => {
    e.preventDefault();
    const { assessId } = props.match.params;

    let values = props.form.getFieldsValue();
    saveDraft({
      id: assessId,
      name: values.name,
      coverKey: (values.userTunQiaoLeftImage || {}).qiniuKey,
      submitData: JSON.stringify(values),
    }, headers);
  }

  const getTokenAndSubmitAssess = async (data) => {
    if (headers && headers["X-Mp-LoginToken"]) {
      console.log("hasToken");
      submitAssess(data, headers);
    } else {
      console.log("noToken");

      const params = {
        action: "getToken"
      };

      dsBridge.call("egghealth.asyncHandler", JSON.stringify(params), async (tokenStr) => {
        if (tokenStr) {
          var tokenInfo = JSON.parse(tokenStr) || {};
          setHeaders(tokenInfo);
          submitAssess(data, tokenInfo);
        } else {
          Toast.fail("获取登录信息失败");
        }
      });
    }
  }

  const submitAssess = async (data, tokenInfo) => {
    var res = await assessApi.submitAssess(data, tokenInfo);
    if (res.errCode !== 0) {
      Toast.fail(res.errMsg);
      if (res.errCode === 4032) {
        setHeaders({});
      }
    } else {
      Toast.success("资料提交成功，请耐心等待评估结果");
      assess.status = "SUBMITTED";
      setAssess({
        ...assess,
        status: "SUBMITTED",
      });
    }
  }

  const saveDraft = async (data, tokenInfo) => {
    var res = await assessApi.saveAssess(data, tokenInfo);
    if (res.errCode !== 0) {
      Toast.fail(res.errMsg);
      if (res.errCode === 4032) {
        setHeaders({});
      }
    } else {
      Toast.success("资料保存成功");
    }
  }

  const onRetry = () => {
    fetchData();
  }

  const myImg = src => <img src={src} className="assess-result-img" alt="" />;

  const { getFieldError, getFieldDecorator, getFieldProps } = props.form;
  var showAssessStatus = (loadStatus === "LOADED" && assess.status !== "PENDING" && assess.status !== "REJECTED");
  var showWarning = (loadStatus === "FAILED");
  var showLoading = (loadStatus === "NONE" || loadStatus === "LOADING");
  var showForm = (!showAssessStatus && !showWarning && !showLoading);
  var showSaveDraft = (loadStatus === "LOADED" && (assess.status === "PENDING" || assess.status === "REJECTED"));

  const bodyList = [{
    label: "腰部",
    value: "腰部",
  }, {
    label: "膝部",
    value: "膝部",
  }, {
    label: "颈肩",
    value: "颈肩",
  }, {
    label: "脚踝",
    value: "脚踝",
  }, {
    label: "其他",
    value: "其他",
  }];

  const ageList = [{
    label: "10岁及以下",
    value: "10"
  }];
  for (var i = 0; i < 49; i++) {
    ageList.push({
      label: `${i + 11}岁`,
      value: `${i + 11}`
    });
  }
  ageList.push({
    label: "60岁及以下",
    value: "60"
  });

  return (
    <div>
      <div style={{ display: showWarning ? "block" : "none" }}>
        <Result
          img={myImg(IMG_WARNING)}
          title="数据加载失败"
          message={<div className="assess-result-message">请刷新页面重试</div>}
        />
        <div className="assess-result-button" onClick={onRetry}>刷新重试</div>
      </div>
      <div style={{display: showLoading ? "block" : "none"}}>
        <div className="assess-loading-text">数据加载中...</div>
      </div>
      <div style={{ display: showAssessStatus ? "block" : "none" }}>
        <Result
          img={myImg(IMG_WAITING)}
          title="正在评估"
          message={<div className="assess-result-message">请耐心等待评估结果</div>}
        />
      </div>
      <div style={{ display: showForm ? "block" : "none" }}>
        <div className="assess-section-tips">
          <div className="assess-service-info">
            <img src={IMG_INFO} alt="" className="assess-section-tips-icon">
            </img>
            以下资料以你参加线下评估的真实情况填写，<br /><br/>
            填写完成后有问题可以联系客服，微信号：{assess.serviceWechat}
          </div>
          <div className="assess-section-rejected-info"
            style={{ display: assess.status === "REJECTED" ? "block" : "none" }}
            dangerouslySetInnerHTML={{ __html: ("资料不符合要求，请修改后再提交：\n" + assess.rejectReason).replace(/\n/g, "<br />") }}
          >
          </div>
        </div>
        <div className="assess-section-title">填写线下评估资料</div>
        <div>
          {getFieldDecorator('name', {
            initialValue: assessData.name,
            rules: [{
              type: 'string',
              required: true,
              message: '请输入称呼',
            }],
          })(
            <TextInputComp title="称呼" placeholder="请输入">
            </TextInputComp>
          )}
          <div className="assess-input-error">{getFieldError('name')}</div>
        </div>
        <div>
          {getFieldDecorator('wechatId', {
            initialValue: assessData.wechatId,
            rules: [{
              type: 'string',
              required: true,
              message: '请输入您的微信号',
            }],
          })(
            <TextInputComp title="微信号" placeholder="请输入您的微信号">
            </TextInputComp>
          )}
          <div className="assess-input-error">{getFieldError('wechatId')}</div>
        </div>
        <div>
          <DatePickerComp title="线下评估日期" placeholder="请选择"
            {...getFieldProps('career', {
              initialValue: null,
              rules: [{
                type: 'string',
                required: true,
                message: '请输入评估日期',
              }],
            })}
          >
          </DatePickerComp>
          {(getFieldError('career') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('career') || []).join(', ')}</div> : null
          }
        </div>
        <div>
          <TextInputComp title="手机号码" placeholder="请输入"
            {...getFieldProps('height', {
              initialValue: assessData.height,
              rules: [{
                type: 'string',
                required: true,
                message: '请输入',
              }],
            })}
          >
          </TextInputComp>
          {(getFieldError('height') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('height') || []).join(', ')}</div> : null
          }
        </div>
        <div>
          <PickerComp title="年龄" placeholder="请选择" data={ageList}
                      {...getFieldProps('age', {
                        initialValue: assessData.age,
                        rules: [{
                          type: 'string',
                          required: true,
                          message: '请选择年龄',
                        }],
                      })}
          >
          </PickerComp>
          {(getFieldError('age') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('age') || []).join(', ')}</div> : null
          }
        </div>
        <div className="assess-section-sep"></div>
        <div className="assess-section-title">疼痛部位</div>
        <div className="assess-section-line"></div>
        <div>
          <SelectComp
            options={bodyList}
            {...getFieldProps('acheList', {
              initialValue: assessData.acheList || [],
              rules: [{
                required: true,
                message: '请选择疼痛部位',
              }],
            })}
          >
          </SelectComp>
          {(getFieldError('acheList') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('acheList') || []).join(', ')}</div> : null
          }
        </div>
        <div className="assess-section-sep"></div>
        <div className="assess-section-title">疼痛症状</div>
        {/* <div className="assess-section-subtitle">
          <div>1.首次疼痛的发生情况和可能原因？</div>
          <div>2.疼痛的具体位置和不舒服的具体感觉是如何的？</div>
          <div>3.在什么情况下会加重不舒服？</div>
        </div> */}
        <div className="assess-section-desc">
          例如：上下楼梯膝盖疼痛加重、久坐腰痛加重、症状是否明显等、这种情况持续的时间，是否反复？
        </div>
        <div className="assess-section-line"></div>
        <div>
          <TextareaItem
            placeholder="请输入"
            rows={8}
            {...getFieldProps('userComplaint', {
              initialValue: assessData.userComplaint,
              rules: [{
                type: 'string',
                required: true,
                message: '请输入问题描述',
              }],
            })}
          >
          </TextareaItem>
          {(getFieldError('userComplaint') || []).join(', ') !== '' ?
            <div className="assess-input-error">{(getFieldError('userComplaint') || []).join(', ')}</div> : null
          }
        </div>
        <WhiteSpace></WhiteSpace>
        <WhiteSpace></WhiteSpace>

        <div className="assess-section-sep"></div>
        <div className="assess-submit-wrapper">
          <div className="assess-submit-btn" onClick={onSubmit}>提交</div>
        </div>
      </div>
      <div
        onClick={onSaveDraft}
        className="assess-save-draft"
        style={{ display: showSaveDraft ? "block" : "none" }}
      >
        保存草稿
      </div>
    </div>
  );
};

export default createDOMForm()(OfflineAssessPage);
