import React from 'react';
import { Modal, Button, WhiteSpace } from 'antd-mobile'

import './index.less';
import IMG_DOWNLOAD from './images/ic_download.png';
import IMG_CLOSE from './images/ic_close.png';

const DownloadAppModal = props => {
  return (
    <div>
      <Modal
        visible={!props.hideModal}
        transparent
        maskClosable={false}
        onClose={props.onClose}
      >
        <div className="download-wrapper">
          <img src={IMG_DOWNLOAD} alt="图片不存在"></img>
          <div className="close-wrapper" onClick={props.onClose}>
            <img src={IMG_CLOSE} alt="图片不存在"></img>
          </div>
          <WhiteSpace size="lg"></WhiteSpace>
          <div className="main-title">新人优惠券</div>
          <div className="main-title">已经发放到你的账户</div>
          <div className="sub-title">请打开APP使用</div>
          <div className="item-wrapper">
            <Button type="warning" onClick={props.onDownload}>去使用</Button>
          </div>
          <WhiteSpace size="lg"></WhiteSpace>
        </div>
      </Modal>
    </div>
  );
};

export default DownloadAppModal;