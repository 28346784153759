import React, { forwardRef } from 'react';
import { Picker } from 'antd-mobile'

import '../textInput/index.less';

const PickerComp = forwardRef((props, _ref) => {

  const handlePickerChange = (val) => {
    if (val && val.length > 0) {
      props.onChange(val[0]);
    }
  }

  let curTitle = "";
  (props.data || []).forEach((d) => {
    if (d.value === props.value) {
      curTitle = d.label;
    }
  })

  return (
    <Picker data={props.data} cols={1} onOk={handlePickerChange}>
      <label className="text-label">
        <div className="text-info" needsclick="true">
          <div className="text-title">{props.title}</div>
          {!(props.value === undefined || props.value === '') ? 
            <div className="text-value">{curTitle}</div> :
            <div className="text-placeholder">{props.placeholder}</div>
          }
        </div>
      </label>
    </Picker>
  );
});

export default PickerComp;